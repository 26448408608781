<script setup lang="ts">
const links = useAttrs().links as
    | {
          hash: string;
          title: string;
      }[]
    | undefined;
</script>

<template>
    <NuxtLayout name="subpage" style="overflow: visible">
        <VContainer fluid style="max-width: 1700px">
            <VRow align="start" style="overflow: visible; position: relative">
                <VCol v-if="links?.length" cols="3" align-self="start" class="d-none d-md-block" style="position: sticky; top: 4rem; z-index: 100">
                    <VCard>
                        <VCardTitle class="text-purple-dark">Explore</VCardTitle>
                        <VDivider></VDivider>
                        <VList>
                            <VListItem
                                v-for="(link, i) in links"
                                :key="i"
                                :to="link.hash"
                                :active="$route.hash === link.hash"
                                color="medica"
                                class="text-caption"
                            >
                                <VListItemTitle :title="link.title">
                                    <VIcon icon="$right" size="sm" color="purple" />
                                    <span class="ml-2">{{ link.title }}</span>
                                </VListItemTitle>
                            </VListItem>
                        </VList>
                    </VCard>
                </VCol>
                <VCol cols="12" :md="links?.length ? 9 : 12">
                    <template #default> <slot></slot> </template>
                </VCol>
            </VRow>
        </VContainer>
        <slot name="section-footer"></slot>
    </NuxtLayout>
</template>
